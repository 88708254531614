import React from "react";
import styled from "styled-components";

const SectionContainer = styled.div`
  padding: 2vh 5vw 0 5vw;
`;

const Title = styled.div`
  color: #143718;
  font-size: 32px;
  font-weight: 800;
  margin: 0 0 1vh 0;
`;

const Content = styled.div`
  margin: 0 0 10vh 0;
  color: #143718;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
`;

const ProjectContent = styled.div`
  margin: 0 0 10vh 0;
  color: #143718;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: wrap;
`;

const EndLine = styled.div`
  background: #14371836;
  height: 1px;
`;

class Section extends React.Component {
  render() {
    return (
      <SectionContainer>
        <Title>{this.props.title}</Title>
        {this.props.project ? (
          <ProjectContent>{this.props.content}</ProjectContent>
        ) : (
          <Content>{this.props.content}</Content>
        )}
        {!this.props.last ? <EndLine></EndLine> : null}
      </SectionContainer>
    );
  }
}

export default Section;

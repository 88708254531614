import React, { Component } from "react";
import styled from "styled-components";

const RatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const SkillName = styled.div``;

const SkillLevel = styled.div``;

const SkillDot = styled.div`
  margin: 0 10px 0 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
`;

class Rating extends Component {
  render() {
    let dots = [];
    let style = {};
    if (this.props.level <= 2) style.backgroundColor = "rgb(255, 95, 59)";
    else if (this.props.level <= 5) style.backgroundColor = "rgb(232, 234, 46)";
    else style.backgroundColor = "rgb(60, 162, 72)";

    for (let i = 0; i < this.props.level; i++) {
      dots.push(<SkillDot key={i} style={style}></SkillDot>);
    }

    return (
      <RatingContainer>
        <SkillName>{this.props.name}</SkillName>
        <SkillLevel>{dots}</SkillLevel>
      </RatingContainer>
    );
  }
}

export default Rating;

import React, { Component } from "react";
import styled from "styled-components";
import Section from "../section/Section";

const ContactContainer = styled.div`
  color: white;
  font-weight: bold;
`;
const ContactInfoContainer = styled.div`
  margin: 5vh 0 0 0;
`;
const Name = styled.div`
  font-size: 24px;
`;
const Phone = styled.div`
  font-size: 16px;
  margin: 2vh 0;
`;
const Email = styled.div`
  font-size: 16px;
`;

class Contact extends Component {
  render() {
    return (
      <ContactContainer>
        <Section
          last
          title={`My contact information`}
          content={
            <ContactInfoContainer>
              <Name>Mikko Ahola</Name>
              <Phone>Phone: 0400889982</Phone>
              <Email>Email: mikkoahola02@gmail.com</Email>
            </ContactInfoContainer>
          }
        ></Section>
      </ContactContainer>
    );
  }
}

export default Contact;

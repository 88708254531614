import React from "react";
import styled from "styled-components";
import igEmbed from "../../images/instagram-brands.svg";
import linkedIn from "../../images/linkedin-brands.svg";
import gitlab from "../../images/gitlab.svg";
import { Link } from "react-router-dom";

const FooterContainer = styled.div`
  background: #143718;
  padding: 3vh 0 1vh 6vw;
  font-family: Arial, Helvetica, sans-serif;
`;

const FooterLinks = styled.ul`
  color: white;
  list-style: none;
  margin: 0;
`;

const FooterLink = styled.li`
  margin: 1vh 0;
`;

const Copyright = styled.div`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin: 3vh 0 0 0;
`;

const EmbedContainer = styled.div`
  display: flex;
`;

const Embed = styled.div`
  background-image: ${igEmbed};
  width: 40px;
  height: 40px;
  background-size: cover;
  margin: 0 2vw 3vh 0;
`;

class Footer extends React.Component {
  render() {
    let linkstyle = { color: "white", cursor: "pointer" };
    return (
      <FooterContainer>
        <FooterLinks>
          <EmbedContainer>
            <Embed>
              <a href="https://www.instagram.com/mikepapaahola/">
                <img src={igEmbed} alt="instagram"></img>
              </a>
            </Embed>
            <Embed>
              <a href="https://www.linkedin.com/in/mikko-ahola-6555b1176/">
                <img src={linkedIn} alt="linkedIn"></img>
              </a>
            </Embed>
            <Embed>
              <a href="https://gitlab.com/miksu579">
                <img src={gitlab} alt="GitLab"></img>
              </a>
            </Embed>
          </EmbedContainer>
          <Link style={linkstyle} to="/contact">
            <FooterLink>Contact me</FooterLink>
          </Link>
          <Link style={linkstyle} to="/projects">
            <FooterLink>Projects</FooterLink>
          </Link>
          <Link style={linkstyle} to="/">
            <FooterLink>Home</FooterLink>
          </Link>
          <Copyright>
            This site has been created using React.js and styled-components
          </Copyright>
        </FooterLinks>
        <Copyright>©2023</Copyright>
      </FooterContainer>
    );
  }
}

export default Footer;

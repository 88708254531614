import React, { Component } from "react";
import styled from "styled-components";
import Section from "../section/Section";
import Project from "../projectCard/ProjectCard";

import scores from "../../images/scores.jpg";
import parallax from "../../images/parallax.jpg";
import plywood from '../../images/plywood-furniture.jpg';
import cottage from '../../images/cottage.jpg';
import quistonnaire from '../../images/quistonnaire.jpg';
import matrix from '../../images/matrix.jpg';
import flipcardsThumbnail from "../../images/flipcardsThumbnail.jpg";

const ProjectListContainer = styled.div``;

const data = [
  {
    title: "Flipcards",
    content:
      "Flipcards is a web app used to learn words for different languages At the moment only english is supported. This app has been created using JavaScript and HTML.",
    image: flipcardsThumbnail,
    link: "http://www.mikkoahola.fi/flipcards",
    buttonText: "VISIT THE LIVE APP"
  },
  {
  title: "Discgolf tracker",
    content:
      "Discgolf tracker is used to track your own scores as you play discgolf on various courses. You can add new courses, new rounds and inspect your scores at scores view. Unfortunately the app doesn't yet save any changes because of lack of database. This app has been created using React.js.",
    image: scores,
    link: "http://www.mikkoahola.fi/discgolftracker",
    buttonText: "VISIT THE LIVE APP"
  },
  {
  title: "Quistonnaire",
    content:
      "Having problems with your coffee table conversations? Fear not, quistonnaire is here! Quistonnaire is a brand new coffee table conversation app in which you can save the most problematic questions in your mind. Quistonnaire has been developed with React.js, Node.js and MySQL stack.",
    image: quistonnaire,
    buttonText: "IN MAINTENANCE"
  },
  {
    title: "Parallax",
    content:
      "Testing out the possibilities of parallax effect as a part of a homepage. This demo has been created using HTML5, CSS and JavaScript",
    image: parallax,
    link: "http://www.mikkoahola.fi/design/parallax",
    buttonText: "VISIT THE LIVE APP"
  },
  {
    title: "Sliding image animation",
    content:
      "Testing out CSS animation and transition for slick looking animations on hovering html elements. Extremely effective as a part of modern desktop website designs. Animations disabled in mobile devices. Content in finnish.",
    image: plywood,
    link: "http://www.mikkoahola.fi/design/slide",
    buttonText: "VISIT THE LIVE APP"
  },
  {
    title: "Changing background between sections",
    content:
      "Create cool background image transitions between your content sections! Especially designed for desktop use. The effect isn't supported by Safari. Includes small tutorial to create the changing background effect",
    image: cottage,
    link: "http://www.mikkoahola.fi/design/changingbackground",
    buttonText: "VISIT THE LIVE APP"
  },
  {
    title: "Matrix background",
    content:
      "Take a look at this awesome matrix themed background animation. It is made with dynamic html, JS and CSS!",
    image: matrix,
    link: "http://www.mikkoahola.fi/design/matrix",
    buttonText: "VISIT THE LIVE APP"
  }

];

class ProjectList extends Component {
  render() {
    return (
      <ProjectListContainer>
        <Section
          project
          last
          title="Projects"
          content={data.map((app, key) => {
            return (
              <Project
                key={key}
                image={app.image}
                title={app.title}
                content={app.content}
                link={app.link}
                buttonText={app.buttonText}
              ></Project>
            );
          })}
        ></Section>
      </ProjectListContainer>
    );
  }
}

export default ProjectList;

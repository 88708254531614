import React from "react";
import styled from "styled-components";
import bg from "../../images/bg.jpg";
import { Link } from "react-router-dom";

const HeaderContainer = styled.div`
  height: 50vh;
`;

const HeaderImg = styled.div`
  background-image: url(${bg});
  background-size: 140%;
  background-position-x: -20vw;
  background-position-y: -6vh;
  height: 50vh;
`;

const HeaderContent = styled.div`
  color: white;
  padding: 4vh 4vw 0 4vw;
  font-weight: bold;
`;

const HeaderName = styled.div`
  color: white;
  z-index: 2;
  font-size: 26px;
`;

const HeaderSummary = styled.ul`
  list-style: none;
  font-size: 38px;
  padding: 5vh 0 0 0;
  line-height: 1.3;
`;

class Header extends React.Component {
  render() {
    return (
      <HeaderContainer>
        <HeaderImg>
          <HeaderContent>
            <Link to="/" style={{ textDecorationLine: "none" }}>
              <HeaderName>{this.props.name}</HeaderName>
            </Link>
            <HeaderSummary>
              <li>{this.props.fancyWords[0]}</li>
              <li>{this.props.fancyWords[1]}</li>
              <li>{this.props.fancyWords[2]}</li>
            </HeaderSummary>
          </HeaderContent>
        </HeaderImg>
      </HeaderContainer>
    );
  }
}

export default Header;

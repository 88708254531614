import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ButtonContainer = styled.div``;
const ButtonDiv = styled.div`
  width: 180px;
  height: 26px;
  padding-top: 10px;
  border: 1px solid #707070;
  background: #143718;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  display: inline-block;
`;

class Button extends Component {
  render() {
    return (
      <ButtonContainer>
        {this.props.linkAdress ? (
          <Link to={this.props.linkAdress}>
            <ButtonDiv>{this.props.text}</ButtonDiv>
          </Link>
        ) : (
          <ButtonDiv>{this.props.text}</ButtonDiv>
        )}
      </ButtonContainer>
    );
  }
}

export default Button;

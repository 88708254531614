import React, { Component } from "react";
import styled from "styled-components";
import Button from "../button/Button";

const ProjectContainer = styled.div`
  margin: 20px 10px 40px 10px;
  max-width: 350px;
  border: 1px solid #70707052;
  border-radius: 6px;
  -webkit-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  margin: 0 0 4vh 0;
  color: #143718;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
`;

const Title = styled.div`
  color: #143718;
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 2vh 0;
`;

const ImageDiv = styled.div`
  height: 200px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-size: cover;
`;

const Info = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const TextWrapper = styled.div``;

class Project extends Component {
  render() {
    return (
      <ProjectContainer>
        <ImageDiv
          style={{ backgroundImage: `url(${this.props.image})` }}
        ></ImageDiv>
        <Info>
          <TextWrapper>
            <Title>{this.props.title}</Title>
            <Content>{this.props.content}</Content>
          </TextWrapper>
          <a href={this.props.link}>
            <Button text={this.props.buttonText}></Button>
          </a>
        </Info>
      </ProjectContainer>
    );
  }
}

export default Project;

import React, { Component } from "react";
import styled from "styled-components";
import Section from "../section/Section";
import Rating from "../rating/Rating";
import Button from "../button/Button";

const HomeContainer = styled.div``;

const Education = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
`;

const EducationContent = styled.li`
  margin: 1vh 0;
`;

const ProjectsContent = styled.div`
  margin: 1vh 0 2vh 0;
`;
const Projects = styled.div``;

const skills = [
  {
    name: "JavaScript",
    level: 10
  },
  {
    name: "React",
    level: 9
  },
  {
    name: "CSS3",
    level: 7
  },
  {
    name: "Python",
    level: 7
  },
  {
    name: "C#",
    level: 2
  },
  {
    name: "Java",
    level: 5
  },
  {
    name: "SQL",
    level: 2
  },
  {
    name: "HTML5",
    level: 8
  }
];

class HomePage extends Component {
  render() {
    skills.sort(function(a, b) {
      return b.level - a.level;
    });
    return (
      <HomeContainer>
        <Section
          title="ABOUT"
          content="Hi, my name is Mikko Ahola! I'm a almost masters student graduate in computer science at University of Jyväskylä.
            I love solving all kinds of problems, for example rubik's cube is one of my favorite pieces of brain candy ever (Although I probably can't solve it without instructions anymore)!
            I'm currently looking for a job as a software developer. I'm interested in pretty much anything where I can use my problem solving skills.
            You can see my skills and education below this section. Rating of the skills represents the level of confidence when working with the technology."
        />
        <Section
          title="SKILLS"
          content={skills.map((skill, index) => {
            return <Rating key={index} level={skill.level} name={skill.name} />;
          })}
        />
        <Section
          title="EDUCATION"
          content={
            <Education>
              <EducationContent>
                Ongoing: Computer Science masters at University of Jyväksylä
                (3rd and also my last year)
              </EducationContent>
              <EducationContent>
              Computer Science bachelor from University of Jyväksylä
              </EducationContent>
              <EducationContent>
                Reserve officers training during military service
              </EducationContent>
              <EducationContent>High school graduate</EducationContent>
            </Education>
          }
        />
        <Section
          last
          title="PROJECTS"
          content={
            <Projects>
              <ProjectsContent>
                I have created some projects for my own use on my spare time.
                You can go check them out from here:
              </ProjectsContent>
              <Button text="CHECK OUT" linkAdress="/projects"></Button>
            </Projects>
          }
        ></Section>
      </HomeContainer>
    );
  }
}

export default HomePage;

import React from "react";
import "./App.css";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ProjectList from "./components/projectList/ProjectList";
import Contact from "./components/contact/Contact";

const AppContainer = styled.div`
  height: 100vh;
  font-family: "Poppins", sans-serif;
`;

class App extends React.Component {
  render() {
    return (
      <Router>
        <AppContainer>
          <Header
            name="Mikko Ahola"
            fancyWords={["Web developer","Student", "Problem solver"]}
          ></Header>
          <Switch>
            <Route path="/" exact component={Home}></Route>
            <Route path="/projects" component={ProjectList}></Route>
            <Route path="/contact" component={Contact}></Route>
          </Switch>
          <Footer></Footer>
        </AppContainer>
      </Router>
    );
  }
}

export default App;
